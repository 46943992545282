import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import VideoBg from "reactjs-videobg";

import Hero from '../components/rows/hero'
import Intro from '../components/rows/intro'
import ImgSection from '../components/rows/imgsection'
import Outro from '../components/rows/outro'
import TwoCol from '../components/rows/twocol'
import Accordion from '../components/elements/accordion'

import Forest from '../images/svg/forest.svg'
import Banner from '../images/img/hero/home-hero.jpg'
import Poster from '../images/video/poster.png'

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="bg-black relative welcome" style={{backgroundImage: `url(${Banner})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
      <div className="white-text flex items-center justify-center flex-col welcome-text z-50">
        <h1>There is a better way</h1>
        <h2 className="fake-h5">The ultimate in wood-drying technology. Saving time and money while helping protect our forests.</h2>        
      </div>
      <img src={Forest} alt="Knockout picture of trees" className="w-full absolute bottom-0 -mb-2" />
    </div>
    <div className="home">
      <Intro 
        home
        subtitle="Made-in-Canada"
        title="Innovative. Reliable. Safe. Easy to operate."
        description="Our made-in-Canada, radio frequency vacuum kilns heat lumber from the inside out, resulting in faster, consistent drying with fewer defects. From shipping pallets and craft furniture making to various lumber processing industries, RF Kiln Tech kilns are the future of wood drying. **Why make the switch to RF Kiln Tech?**"                 
      />
      <ImgSection
        bgPurple
        video
        buttonOrange
        subtitle="Want to learn more?"
        title="Our kilns are perfect for:"
        description="specialty woods, heavy stock, timbers, log & timber frame homes, live edge wood, dimensional lumber, slabs, billets … and more."      
        image={Poster}
        link="About RF Kiln Tech"
        href="/our-background"
      />
    </div>
      <Outro
        home
        subtitle="Interested in purchasing from us?"
        title="Discover the business case for purchasing a RF Kiln"               
      />
  </Layout>
)

export default IndexPage